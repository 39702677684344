<template>
  <div class="VideoNews">
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
      <van-cell class="content" v-for="(item,index) in tableListData" :key="index" @click="getVideo(item)">
        <div  class="contenRight">
          <div class="contentflex">
              <a>
                <img src="@/assets/video.png" alt="">
                <img :src="item.image" alt="" class="imgHead">
              </a>
              <p>{{item.title}}</p>
              <strong class="flexDispay">
                {{item.create_time}}
              </strong>
          </div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>

import {
  journalismList
} from "@/api/index";

export default {
  components: {
  },
  data(){
    return{
      SrcUrl:'',
      tableListData:[],
      videoStart:false,
      loading:false,
      finished:false,
      page: 1,//请求第几页
      pageSize: 10,//每页请求的数量
      total:0,
    }
  },
  created(){
    this.initTable();
  },
  methods:{
    initTable(){
      let datas = {
        page:this.page,
        type:3,
        categoryId:Number(this.$route.query.id)
      }
      journalismList(datas).then(res=>{
        this.tableFilter = res.data.data;
        this.total = res.data.total;
        this.loading = false;
        if (res.data.newsList.list == null || res.data.newsList.list.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }

        // 将新数据与老数据进行合并
        this.tableListData = this.tableListData.concat(res.data.newsList.list);
       
       //如果列表数据条数>=总条数，不再触发滚动加载
        if (this.tableListData.length >= this.total) {
          this.finished = true;
        }
      })
    },
    onLoad() {
      this.page++;
      this.initTable();
    },
    filterbBtn(){
      console.log(1111)
    },
    getVideo(row){
      this.$router.push({
        name:'video',
        query:{
          urlVideo:row.video_url
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.VideoNews{
  background: #fff;
  padding-top: 10px;
}
.content{
  padding: 0;
}
.imgHead{
    width: 100%;
    height: 100%;
}
.contenRight{
    display: flex;
    justify-content: center;
    .contentflex{
        padding: 13px;
        flex:1;
        p{
            display: inherit;
            padding:10px 0 0 9px;
            text-align: left;
            margin: 0;
            font-weight: 800;
        }
        img{
          &:first-child{
            position: absolute;
            width: 43px;
          }
          &:last-child{
            border-radius: 10px;
          }
        }
        a{
          width: 100%;
          height: 74%;
          display: inline-block;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        }
    }
}
.flexDispay{
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #777;
    padding: 10px 0 0 9px;
}

</style>
